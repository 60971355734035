import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class Paddleboarding extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Paddleboarding" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className='pt-5'>Want to go paddleboarding in The Lake District?</h1>
            <h5>Here are some useful resources:</h5>
            <ul>
              <li><a href="https://lakedistrictpaddleboarding.co.uk/where-we-paddle/">Lake District Paddle Boarding - Where we paddle</a></li>
              <li><a href="https://www.windermerecanoekayak.com/pages/sup-hire-lake-district">Windermere Canoe Kayak - Stand Up Paddleboard Hire</a></li>
              <li><a href="https://www.brockhole.co.uk/activities/boat-hire/paddleboarding/">Brockhole - Paddleboarding</a></li>
              <li><a href="https://www.conistonboatingcentre.co.uk/boathire/paddleboards">Coniston Boating Centre - Paddle boards</a></li>
            </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Paddleboarding

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
